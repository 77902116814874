import React from 'react'

const Home = () => {
  return (
    <div className='container'>
        <div className='row'>
            <div className='col-3'><h1>Hello</h1></div>
            <div className='col-3'><h1>I'M</h1></div>
            <div className='col-3'><h1>Mr. W</h1></div>
        </div>
    </div>
  )
}

export default Home